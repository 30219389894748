import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Components
import CheckoutButton from "./CheckoutButton";
import ClinicalModal from "./ClinicalModal";
import ProductHeroCarousel from "./ProductHeroCarousel";
import StarRating from "./StarRating";
import SupplementFacts from "./SupplementFacts";
import Container from "../Container";
import Row from "../Row";
import Text from "../Text";

// Utils
import { Font, Color, rem, media, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import NameMap from "../../utils/nameMap";
import { variation } from "../../utils/launchDarkly";
import metrics from "../../utils/metrics";

const HeroArea = styled.section.attrs({ id: "product-hero" })`
  background-color: ${p => (p.backgroundColor ? p.backgroundColor : "#EEECF5")};
  width: 100%;
  cursor: initial;
`;

const StyledContainer = styled(Container).attrs({
  id: "product-hero_container",
  className: "container",
})`
  height: 100%;
  overflow: visible !important;
  margin-top: 52px;

  ${responsive.sm`
    margin-bottom: 24px;
  `}

  ${responsive.md`
    margin-top: 68px;
    margin-bottom: 40px;
  `}
`;

const StyledRow = styled(Row).attrs({
  id: "product-hero_container_row",
  className: "row",
})`
  height: 100%;

  ${responsive.sm`
    padding-top: 24px;
  `}

  ${responsive.md`
    padding-top: 40px;
  `}
`;

const ContentArea = styled.div.attrs({
  id: "product-hero_container_row_content",
  className: "col-12 col-sm-5",
})`
  display: flex;
  flex-direction: column;

  order: 0;
  padding-left: 0;

  ${media.mobile`
    order: 1;
    padding: 16px 24px 0;
  `}

  ${responsive.lg`
    justify-content: center;
  `}
`;

const Divider = styled.hr`
  background: rgba(0, 0, 0, 0.16);
  height: 1px;
  border: none;
  margin: 32px 0 0;

  ${responsive.sm`
    display: none;
  `}
`;

const ImageArea = styled.div.attrs({
  id: "product-hero_container_row_image",
  className: "col-12 col-sm-6 offset-sm-1",
})`
  order: 1;
  padding-left: 0;

  ${media.mobile`
    order: 0;
    padding: 0;
  `}
`;

const ProductName = styled.h1.attrs({
  id: "product-hero_container_row_content_product-name",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  margin: 0;
  font-weight: 500;
  text-align: left;

  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;

  ${responsive.md`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: -0.5px;
  `}

  em {
    ${Font.dutch}
    font-style: italic;
  }
`;

const ProductDescription = styled.div.attrs({
  id: "product-hero_container_row_content_product-description",
})`
  margin-top: 16px;

  ${responsive.md`
    margin-top: 24px;
  `}

  p {
    margin: 0;
    font-weight: 300;
    letter-spacing: 0px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }
`;

const LinkContainer = styled.div`
  margin-top: 8px;

  ${responsive.md`
    margin-top: 16px;
  `}
`;

const LinkButton = styled.button`
  margin-right: 24px;

  ${responsive.md`
    margin-right: 32px;
  `}

  &:last-of-type {
    margin-right: 0;
  }

  appearance: none;
  border: none;
  background: none;

  display: inline-block;
  padding: 0;

  font-weight: 500;
  letter-spacing: 0px;
  font-size: ${rem(14)};
  line-height: ${rem(24)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  color: ${Color.ritualBlue};
  border-bottom: 2px solid ${Color.ritualBlue};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.56;
  }
`;

const ButtonWrapper = styled.div.attrs({
  id: "product-hero_container_row_content_button-wrapper",
})`
  margin: 32px 0 16px;

  display: flex;
  justify-content: center;

  ${responsive.sm`
    justify-content: flex-start;
  `}

  ${responsive.md`
    margin: 40px 0 32px;
  `}

  a {
    width: 100%;

    ${responsive.sm`
      width: auto;
    `}
  }
`;

const BarrierBusterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;

  ${responsive.md`
    padding-top: 0;
  `}
`;

const BarrierBuster = styled.p`
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  font-weight: 300;
  letter-spacing: 0px;

  font-size: ${rem(14)};
  line-height: ${rem(20)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `};

  svg {
    height: 10px;
    width: 13px;
    margin-right: 16px;
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
  margin: 0 9px;

  ${responsive.sm`
    margin: 0;
  `}
`;

const BarrierBusters = intl.unformattedTranslation(
  "product.hero.barrier-busters",
);

// If these barrier busters continue to become more product specific, this field
// should be migrated to Contentful.
const EPREBarrierBusters = [
  ...BarrierBusters,
  ...intl.unformattedTranslation("product.hero.barrier-busters-epre"),
];

export default class ProductHero extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reviewsLinkActive: true,
      showReviews: false,
    };

    this.factsPopup = React.createRef();
  }

  componentDidMount() {
    const showReviews = variation("pdp-hero-reviews-star-rating");
    this.setState({
      showReviews,
    });
  }

  appendAccessibleFootnoteLink() {
    // include link to footer for a11y
    const { headerDescription } = this.props;
    const htmlHeaderDescription = `<p>${headerDescription}</p>`;
    const accessibleFooterLinkHtml =
      '<a aria-describedby="disclaimer-footnote-label" href=#disclaimer-footnote id="footnote-ref">*</a>';

    return htmlHeaderDescription.replace("*", accessibleFooterLinkHtml);
  }

  showSupplementFacts() {
    metrics.track("Overlay Opened", {
      location: "PDP Hero",
      title: "Supplement Facts",
    });

    this.factsPopup.current.open();
  }

  showClinicalModal() {
    metrics.track("Overlay Opened", {
      location: "PDP Hero",
      title: "Our Clinical Study",
    });
  }

  render() {
    let {
      productName,
      buttonPrice,
      buttonCta,
      trackCTAClick,
      heroBackgroundColor,
      featuredImages,
      zoomImages,
      reviewScore,
      reviewCount,
      product,
      clinicalData,
    } = this.props;

    const { showReviews } = this.state;

    let checkoutButtonProps = {
      buttonText: buttonPrice,
      appendText: buttonCta,
      className: "product-hero__btn__primary-cta",
      stockStatus: product.stockStatus,
      trackCTAClick: trackCTAClick.bind(null, buttonCta, "Hero"),
      product,
      id: "product-hero_container_row_content_button-wrapper_button",
    };

    const headerDescriptionHtml = this.appendAccessibleFootnoteLink();

    const barrierBusters =
      product.sku === "EPRE60" ? EPREBarrierBusters : BarrierBusters;

    return (
      <HeroArea
        backgroundColor={heroBackgroundColor}
        aria-labelledby="product-name"
      >
        <StyledContainer>
          <StyledRow>
            <ContentArea>
              <ProductName
                dangerouslySetInnerHTML={{ __html: NameMap(productName).html }}
                id="product-name"
              />
              {showReviews && !!reviewScore && (
                <StarRating
                  readReviews={true}
                  score={reviewScore}
                  count={reviewCount}
                  productHero={true}
                />
              )}

              <ProductDescription
                dangerouslySetInnerHTML={{ __html: headerDescriptionHtml }}
              />

              <LinkContainer>
                <LinkButton onClick={this.showSupplementFacts.bind(this)}>
                  <Text
                    id="product.hero.supplement-facts"
                    defaultMessage="Supplement Facts"
                  />
                </LinkButton>

                {clinicalData && (
                  <LinkButton
                    onClick={this.showClinicalModal.bind(this)}
                    id="product-hero_container_row_content_clinical-link"
                    data-modal="clinical-modal"
                  >
                    <Text
                      id="product.hero.clinical-study"
                      defaultMessage="Our Clinical Study"
                    />
                  </LinkButton>
                )}
              </LinkContainer>

              <ButtonWrapper>
                <CheckoutButton {...checkoutButtonProps} />
              </ButtonWrapper>

              <BarrierBusterWrapper>
                {barrierBusters.map((text, index) => {
                  return (
                    <BarrierBuster key={index}>
                      <Icons.CheckYellow />
                      {text}
                    </BarrierBuster>
                  );
                })}
              </BarrierBusterWrapper>
              <Divider />
            </ContentArea>

            <ImageArea>
              <CarouselWrapper>
                <ProductHeroCarousel
                  sku={product.sku}
                  images={featuredImages}
                  zoomImages={zoomImages}
                />
              </CarouselWrapper>
            </ImageArea>
          </StyledRow>
        </StyledContainer>

        <SupplementFacts
          supplementFacts={product.supplementFacts}
          ref={this.factsPopup}
        />
        <ClinicalModal {...clinicalData} />
      </HeroArea>
    );
  }
}
