import React from "react";
import { findDOMNode } from "react-dom";
import styled from "styled-components";
import Img from "gatsby-image";

import ScrollableOverflow from "../ScrollableOverflow";
import MagicZoom from "../MagicZoom";

// Services
import intl from "../../services/intl";

// Utils
import { Icons } from "../../utils/svg";
import { Color, responsive } from "../../utils/style";
import { getPlanForProductSku } from "../../utils/planToProduct";
import metrics from "../../utils/metrics";

const ImageContainer = styled.button`
  position: relative;
  scroll-snap-align: start;

  appearance: none;
  border: none;
  background: none;
  padding: 0;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  width: ${p => {
    // We manually calculate and round width here, instead of using something
    // likw 70vw. If the width has a trailing decimal (e.g. 260.5), the it
    // seems to make the entire image carousel draggable on mobile iOS devices.
    return Math.ceil(p.windowWidth * 0.7);
  }}px;

  margin-right: 9px;

  &:last-of-type {
    margin-right: 0;
  }

  ${responsive.sm`
    width: 300px;
    margin-right: 20px;
  `}

  ${responsive.md`
    width: 400px;
    margin-right: 26px;
  `}

  ${responsive.lg`
    width: 500px;
    margin-right: 30px;
  `}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;

  ${responsive.md`
    top: 28px;
    left: 24px;
  `}

  svg {
    width: 56px;
    height: 56px;

    ${responsive.md`
      width: 92px;
      height: 92px;
    `}
  }
`;

const Controls = styled.div`
  display: flex;
  position: absolute;
  bottom 0;
  left: 250px;

  ${responsive.md`
    left: 344px;
  `}

  ${responsive.lg`
    left: 446px;
  `}
`;

const ControlButton = styled.button`
  appearance: none;
  background: none;
  border: none;

  width: 60px;
  height: 52px;
  padding: 0;
  background-color: ${Color.white};

  display: flex;
  justify-content: center;
  align-items: center;

  ${responsive.md`
    width: 69px;
    height: 56px;
  `}

  svg {
    width: 26px;
    height: 16px;

    g {
      stroke-width: 1.75;
    }
  }

  &:hover {
    svg {
      opacity: 0.56;
    }
  }

  &:disabled {
    svg {
      opacity 0.24;
    }
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

export default class ProductHeroCarousel extends React.Component {
  constructor() {
    super();

    this.state = {
      zoom: false,
      index: 0,
      leftButtonEnabled: false,
      rightButtonEnabled: true,
      windowWidth: 0,
      isMobile: false,
    };

    this.scrollableRef = React.createRef();
    this.imageRefs = [];

    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);

    this.domNodes = this.imageRefs.map(ref => {
      return findDOMNode(ref);
    });

    // Once the image size has been defined, scroll to the first image.
    this.handleResize().then(() => {
      this.scrollableRef.current.scrollToIndex(0, "left", false);
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleImageClick(index) {
    metrics.track("Image Zoom", {
      location: "PDP Carousel",
    });

    this.setState({
      zoom: true,
      index,
    });
  }

  onClose() {
    this.setState({
      zoom: false,
    });
  }

  handleResize() {
    return new Promise(resolve => {
      this.setState(
        {
          windowWidth: window.innerWidth,
          isMobile: window.innerWidth < 750,
        },
        resolve,
      );
    });
  }

  toggleLeftButton(enabled) {
    this.setState({
      leftButtonEnabled: enabled,
    });
  }

  toggleRightButton(enabled) {
    this.setState({
      rightButtonEnabled: enabled,
    });
  }

  previousImage() {
    this.trackImageScroll();
    this.updateIndex(this.state.index - 1);
  }

  nextImage() {
    this.trackImageScroll();
    this.updateIndex(this.state.index + 1);
  }

  trackImageScroll() {
    metrics.track("Image Scroll", {
      location: "Desktop PDP Carousel",
    });
  }

  updateIndex(index) {
    index = Math.max(0, index);
    index = Math.min(index, this.props.images.length - 1);

    if (index === this.state.index) return;

    this.scrollToIndex(index);
  }

  scrollToIndex(index) {
    this.setState({
      index,
    });
    this.scrollableRef.current.scrollToIndex(index, "left");
  }

  renderFlavorIcon(plan) {
    const description = plan.variantDescription.toLowerCase();
    let icon;
    if (description.includes(intl.t("product.icon.mint", "mint"))) {
      icon = <Icons.FlavorMint />;
    } else if (description.includes(intl.t("product.icon.berry", "berry"))) {
      icon = <Icons.FlavorCitrusBerry />;
    } else if (description.includes(intl.t("product.icon.citrus", "citrus"))) {
      icon = <Icons.FlavorCitrus />;
    }

    if (!icon) {
      return null;
    }

    return <IconWrapper>{icon}</IconWrapper>;
  }

  renderImages() {
    const { sku, images } = this.props;
    const { windowWidth } = this.state;

    const plan = getPlanForProductSku(sku);

    return images.map((image, i) => {
      return (
        <ImageContainer
          key={i}
          onClick={this.handleImageClick.bind(this, i)}
          windowWidth={windowWidth}
        >
          <Img
            id={`product-hero_container_row_image_slide-${i}_image`}
            ref={r => (this.imageRefs[i] = r)}
            fluid={image.fluid}
            loading="eager"
            fadeIn={false}
            alt={image.description}
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
              width: "100%",
              height: "100%",
            }}
          />
          {i === 0 && this.renderFlavorIcon(plan)}
        </ImageContainer>
      );
    });
  }

  renderCarousel() {
    const { isMobile, rightButtonEnabled, leftButtonEnabled } = this.state;

    const overflowOptions = {
      bleedLeft: false,
      bleedRight: true,
      disableScroll: true,
    };

    if (isMobile) {
      overflowOptions.bleedLeft = true;
      overflowOptions.disableScroll = false;
    }

    return (
      <>
        <ScrollableOverflow
          ref={this.scrollableRef}
          {...overflowOptions}
          isAtStart={this.toggleLeftButton.bind(this, false)}
          isAfterStart={this.toggleLeftButton.bind(this, true)}
          isAtEnd={this.toggleRightButton.bind(this, false)}
          isBeforeEnd={this.toggleRightButton.bind(this, true)}
        >
          {this.renderImages()}
        </ScrollableOverflow>

        {!isMobile && (
          <Controls>
            <ControlButton
              disabled={!leftButtonEnabled}
              onClick={this.previousImage.bind(this)}
            >
              <Icons.ArrowRoundedLeft />
            </ControlButton>
            <ControlButton
              disabled={!rightButtonEnabled}
              onClick={this.nextImage.bind(this)}
            >
              <Icons.ArrowRoundedRight />
            </ControlButton>
          </Controls>
        )}
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderCarousel()}
        {this.state.zoom && (
          <MagicZoom
            images={this.props.images}
            zoomImages={this.props.zoomImages}
            domNodes={this.domNodes}
            index={this.state.index}
            onClose={this.onClose.bind(this)}
            onIndexChange={this.scrollToIndex.bind(this)}
          />
        )}
      </>
    );
  }
}
