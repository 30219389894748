import React from "react";
import styled, { css } from "styled-components";

// Components
import MagicModal from "../MagicModal";

// Utils
import { rem, Color, responsive, Font } from "../../utils/style";
import { Icons } from "../../utils/svg";

const contentStyle = css`
  padding: 48px 24px 40px;

  ${responsive.sm`
    padding: 40px;
    max-width: 460px;
    max-height: 614px;
  `};

  ${responsive.md`
    padding: 56px 80px;
    max-width: 774px;
  `};

  ${responsive.lg`
    padding: 56px 100px;
    max-width: 770px;
  `};
`;

const ModalContentWrapper = styled.div`
  p {
    font-size: ${rem(16)};
    line-height: 22px;
    letter-spacing: 0;
    margin-bottom: 0;

    ${responsive.lg`
      font-size: ${rem(18)};
      line-height: 28px;
    `};
  }
`;

const TitleWrapper = styled.div`
  border-bottom: 2px solid ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: 32px;
  letter-spacing: -0.2px;

  p {
    margin-bottom: 24px;

    ${responsive.lg`
      font-size: ${rem(30)};
      letter-spacing: -0.4px;
      line-height: 36px;
      margin-bottom: 32px;
    `};
  }

  .italic {
    ${Font.dutch};
    font-style: italic;
  }
`;

const ResultContainer = styled.div`
  margin-top: 24px;

  ${responsive.lg`
    margin-top: 32px;
  `};
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const ResultWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  .light {
    color: rgba(20, 43, 111, 0.56);
    font-weight: 300;
  }

  &:last-of-type {
    margin-bottom: 40px;
  }

  ${responsive.sm`
    width: 272px;
  `};

  ${responsive.md`
    width: auto;
  `};

  ${responsive.lg`
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 56px;
    }
  `};
`;

const SummaryTitleWrapper = styled.div`
  margin-bottom: 8px;
  p {
    line-height: 26px;

    ${responsive.lg`
      line-height: 28px;
    `};
  }
`;

const SummaryWrapper = styled.div`
  ${Font.dutch};
  p {
    line-height: 26px;

    ${responsive.lg`
      line-height: 28px;
    `};
  }
`;

export default class ClinicalModal extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    this._initTimeout = setTimeout(() => {
      const anchorSelector = 'button[data-modal="clinical-modal"]';
      const anchor = document.querySelector(anchorSelector);

      if (!anchor) return;

      this._clickHandler = this.toggleVisibility.bind(this);
      anchor.addEventListener("click", this._clickHandler);
    }, 500);
  }

  componentWillUnmount() {
    if (this._initTimeout) clearTimeout(this._initTimeout);
    if (!this._clickHandler) return;

    const anchorSelector = 'button[data-modal="clinical-modal"]';
    const anchor = document.querySelector(anchorSelector);

    anchor.removeEventListener("click", this._clickHandler);
  }

  toggleVisibility(event) {
    if (event) event.preventDefault();
    const isOpen = !this.state.isOpen;
    this.setState({ isOpen });
  }

  render() {
    let {
      title,
      firstResult,
      secondResult,
      summaryTitle,
      summary,
    } = this.props;

    if (!title || !firstResult || !secondResult || !summaryTitle || !summary) {
      return null;
    }

    title = title.childMarkdownRemark.html;
    firstResult = firstResult.childMarkdownRemark.html;
    secondResult = secondResult.childMarkdownRemark.html;
    summaryTitle = summaryTitle.childMarkdownRemark.html;
    summary = summary.childMarkdownRemark.html;

    const { isOpen } = this.state;

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={this.toggleVisibility.bind(this)}
        contentStyle={contentStyle}
        mobileFullPage={true}
      >
        <TitleWrapper data-test-title-wrapper>
          <div
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          ></div>
        </TitleWrapper>

        <ModalContentWrapper>
          <ResultContainer>
            <ResultWrapper data-test-first-result-wrapper>
              <IconWrapper>
                <Icons.ArrowRoundedUp />
              </IconWrapper>
              <div
                dangerouslySetInnerHTML={{
                  __html: firstResult,
                }}
              ></div>
            </ResultWrapper>

            <ResultWrapper data-test-second-result-wrapper>
              <IconWrapper>
                <Icons.ArrowRoundedUp />
              </IconWrapper>
              <div
                dangerouslySetInnerHTML={{
                  __html: secondResult,
                }}
              ></div>
            </ResultWrapper>
          </ResultContainer>

          <SummaryTitleWrapper data-test-summary-title-wrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: summaryTitle,
              }}
            ></div>
          </SummaryTitleWrapper>
          <SummaryWrapper data-test-summary-wrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: summary,
              }}
            ></div>
          </SummaryWrapper>
        </ModalContentWrapper>
      </MagicModal>
    );
  }
}
