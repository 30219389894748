import React, { Component } from "react";
import Helmet from "react-helmet";

// Utils
import config from "../../utils/siteConfig";
import { getGenderFromSKU, getAgeGroupFromSKU } from "../../utils/product";

// Components
import GeneralTags from "./GeneralTags";

class ProductSEO extends Component {
  static defaultProps = {
    productAttributes: {
      brand: "Ritual",
      availability: "in stock",
      condition: "new",
      category:
        "525 - Health & Beauty > Health Care > Fitness & Nutrition > Vitamins & Supplements",
      currency: "USD",
    },
  };

  getSchemaStockStatus(stockStatus) {
    switch (stockStatus) {
      case "preorder":
        return "http://schema.org/PreOrder";
      case "out of stock":
        return "http://schema.org/OutOfStock";
      case "in stock":
      default:
        return "http://schema.org/InStock";
    }
  }

  render() {
    const {
      pagePath,
      title,
      description,
      image,
      image: { url: imgUrlPartial, width: imgWidth, height: imgHeight },
      productAttributes,
    } = this.props;

    const pageUrl = `${config.siteUrl}/${pagePath}`;
    const imgUrl = `https:${imgUrlPartial}`;

    if (!productAttributes) {
      console.warn("Missing productAttributes from page SEO");
    }

    let productMeta = [
      { property: "product:brand", content: productAttributes.brand },
      {
        property: "product:availability",
        content: productAttributes.availability,
      },
      { property: "product:condition", content: productAttributes.condition },
      {
        property: "product:retailer_item_id",
        content: productAttributes.productId,
      },
      { property: "og:price:amount", content: productAttributes.amount },
      { property: "og:price:currency", content: productAttributes.currency },
      { property: "product:price:amount", content: productAttributes.amount },
      {
        property: "product:price:currency",
        content: productAttributes.currency,
      },
      { property: "product:category", content: productAttributes.category },
    ];

    const sku = productAttributes.sku;
    const gender = getGenderFromSKU(sku);
    const ageGroup = getAgeGroupFromSKU(sku);

    if (gender) {
      productMeta.push({ property: "product:gender", content: gender });
    }

    if (ageGroup) {
      productMeta.push({ property: "product:age_group", content: ageGroup });
    }

    // Overwrite properties below with new image attributes
    let meta = [
      { name: "image", content: imgUrl },
      { property: "og:type", content: "product.item" },
      { property: "og:image", content: imgUrl },
      { property: "og:image:width", content: imgWidth },
      { property: "og:image:height", content: imgHeight },
      { name: "twitter:image", content: imgUrl },
      ...productMeta,
    ];

    const availability = this.getSchemaStockStatus(
      productAttributes.availability,
    );

    let productSchema = [
      {
        "@context": "http://schema.org",
        "@type": "Product",
        name: productAttributes.name,
        description: productAttributes.description,
        brand: productAttributes.brand,
        image: {
          "@type": "ImageObject",
          url: imgUrl,
          width: imgWidth,
          height: imgHeight,
        },
        sku: productAttributes.productId,
        mpn: productAttributes.productId,
        offers: {
          "@type": "Offer",
          priceCurrency: "USD",
          price: productAttributes.amount,
          availability,
          url: pageUrl,
        },
      },
    ];

    if (productAttributes.reviewCount > 0) {
      productSchema[0].aggregateRating = {
        "@type": "AggregateRating",
        ratingValue: productAttributes.ratingValue,
        reviewCount: productAttributes.reviewCount,
      };
      if (productAttributes.topReview) {
        productSchema[0].review = {
          "@type": "Review",
          name: productAttributes.topReview.title,
          reviewBody: productAttributes.topReview.content,
          reviewRating: {
            "@type": "Rating",
            ratingValue: productAttributes.topReview.score,
          },
          author: {
            "@type": "Person",
            name: productAttributes.topReview.user.display_name,
          },
        };
      }
    }

    return (
      <div>
        <GeneralTags
          pagePath={pagePath}
          title={title}
          description={description}
          image={image}
          ogTitle={productAttributes.name}
        />
        <Helmet meta={meta}>
          <script type="application/ld+json">
            {JSON.stringify(productSchema)}
          </script>
        </Helmet>
      </div>
    );
  }
}

export default ProductSEO;
