export const ProductSKU = {
  EFW60: "EFW60",
  E50PLUS60: "E50PLUS60",
  EPRE60: "EPRE60",
  EPOST60: "EPOST60",
  EFM60: "EFM60",
  EFM50PLUS60: "EFM50PLUS60",
  EFTG60: "EFTG60",
  EFTB60: "EFTB60",
  EFKIDS90: "EFKIDS90",
};

export const ProductDemographics = {
  EFW60: "Womens 18+",
  E50PLUS60: "Womens 50+",
  EPRE60: "Prenatal",
  EPOST60: "Postnatal",
  EFM60: "Mens 18+",
  EFM50PLUS60: "Mens 50+",
  EFTG60: "Teen Girls",
  EFTB60: "Teen Boys",
  EFKIDS90: "Kids 4+",
};

export function getGenderFromSKU(sku) {
  switch (sku) {
    case ProductSKU.EFW60:
    case ProductSKU.E50PLUS60:
    case ProductSKU.EPRE60:
    case ProductSKU.EPOST60:
    case ProductSKU.EFTG60:
      return "female";
    case ProductSKU.EFM60:
    case ProductSKU.EFM50PLUS60:
    case ProductSKU.EFTB60:
      return "male";
    case ProductSKU.EFKIDS90:
    default:
      return "unisex";
  }
}

export function getAgeGroupFromSKU(sku) {
  switch (sku) {
    case ProductSKU.EFKIDS90:
      return "kids";
    case ProductSKU.EFTG60:
    case ProductSKU.EFTB60:
      return "teen";
    case ProductSKU.EFW60:
    case ProductSKU.EFM60:
    case ProductSKU.E50PLUS60:
    case ProductSKU.EFM50PLUS60:
    case ProductSKU.EPRE60:
    case ProductSKU.EPOST60:
      return "adult";
    default:
      return "";
  }
}

export function getIncentiveDemographicFromSKU(sku) {
  switch (sku) {
    case ProductSKU.EFKIDS90:
      return "Kids 4+";
    case ProductSKU.EFTG60:
      return "Hers";
    case ProductSKU.EFTB60:
      return "His";
    case ProductSKU.EFW60:
    case ProductSKU.EFM60:
      return "Ages 18+";
    case ProductSKU.E50PLUS60:
    case ProductSKU.EFM50PLUS60:
      return "Ages 50+";
    case ProductSKU.EPRE60:
      return "Prenatal";
    case ProductSKU.EPOST60:
      return "Postnatal";
    default:
      return "";
  }
}

export function getEssentialHeadline({ subCategory, genders, ageRanges }) {
  if (subCategory === "Protein") {
    return "essential.protein";
  }
  if (ageRanges.includes("4-12")) {
    return "essential.kids";
  }
  if (ageRanges.includes("13-17")) {
    return "essential.teens";
  }
  if (genders.includes("Men")) {
    return "essential.men";
  }
  return "essential.women";
}
