import React from "react";
import styled from "styled-components";

// Animations
import ScrollMagic from "scrollmagic";
import gsap from "gsap";

import $ from "jquery";

//Utils
import { Font, media, atLeast, rem, responsive } from "../../utils/style";
import { stripWrappingParagraphTag } from "../../utils/contentful";

// Components
import Container from "../Container";
import Row from "../Row";
import Img from "gatsby-image";

// Styled Elements
const TimeLineWrapper = styled(Container).attrs({
  id: "timeline",
  role: "section",
  "aria-labelledby": "timeline-header",
})`
  margin: 0 auto 80px;
  position: relative;
  overflow: visible !important;

  ${responsive.md`
    margin: 0 auto 120px;
  `}
`;

const TimelineRow = styled(Row)``;

const ImageArea = styled.div.attrs({
  className: "col-12 col-sm-6",
})`
  position: relative;
  top: 0px;
`;

const CircleWrapper = styled.div.attrs({
  className: "timeline__circle", // Required className for scrollmagic
})``;

const TimeLineImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  backface-visibility: hidden;
  max-width: 100%;
  margin: 0;
  z-index: 100;
  width: 470px;
  height: 470px;

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${media.tablet`
    display: block;
    margin: 0 auto;
    padding-bottom: 100%;
    height: 0;
  `}

  ${media.mobile`
      position: relative !important;
      width: 100%;
      top: 0;

      .gatsby-image-wrapper, .gatsby-image-wrapper > * {
          width: 100% !important;
          height: 100% !important;
      }

        img {
          width: 100%;
          height: 100%;
        }
    `}
`;

const TimeLineArea = styled.div.attrs({
  className: "col-12 col-sm-5 offset-sm-1",
})`
  position: relative;
  padding-right: 20px;
`;

const TimelineIntroHeader = styled.h2.attrs({
  id: "timeline-header",
})`
  ${Font.circular}
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  letter-spacing: -0.4px;
  font-weight: 500;
  margin: 24px 0 16px;

  ${responsive.sm`
    margin-top: 0;
    margin-bottom: 24px;
  `};

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
  `};

  em {
    ${Font.dutch};
    font-style: italic;
  }
`;

const TimelineIntroSubhead = styled.p`
  ${Font.dutch}
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  margin: 0 0 56px;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 40px;
  `}
`;

const Line = styled.div.attrs({
  className: "timeline__line",
})`
  left: calc(50% + 7px + 20px);
  height: calc(100% + 42px);
  position: absolute;
  left: 6px;
  height: calc(100% + 25px);
  width: 2px;
  background-color: #b3bbd1;

  ${media.tablet`
    left: 16px;
    height: calc(100% + 42px);
  `};
`;

const TimeLineContent = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 3px;
`;

const TimelineItem = styled.div`
  padding: 0;
  position: relative;
  margin-bottom: 40px;
  padding-right: 20px;
  padding-left: 40px;
  transition: opacity 0.4s;
  box-sizing: border-box !important;
  max-width: 442px;

  ${media.mobile`
    padding-left: 40px;
    width: auto;
  `}

  opacity: 0.2;
  transition: opacity 0.4s;
`;

const LastMarker = styled.div`
  display: none;
  position: absolute;
  left: 3px;
  bottom: 0;
  width: 4px;
  height: 4px;
  border: 2px solid #b3bbd1;
  background-color: #b3bbd1;
  border-radius: 18px;
  z-index: 11;
  box-sizing: content-box !important;

  ${media.tablet`
    left: 13px;
  `};
`;

const ItemMarker = styled.span`
  position: absolute;
  left: 1px;
  width: 8px;
  height: 8px;
  border: 2px solid #ced5e1;
  background-color: #ffffff;
  border-radius: 18px;
  z-index: 11;
  box-sizing: content-box !important;

  ${media.tablet`
        left: 11px;
    `};
`;

const ItemFill = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #142b6f;
  border-radius: 13px;
  top: 2px;
  left: 2px;
  z-index: 12;
  box-sizing: content-box !important;

  ${media.tablet`
      left: 12px;
    `};
`;

const ItemContent = styled.div`
  ${p =>
    p.hasMonth
      ? `
    transform: translateY(-6px);
  `
      : `
    transform: translateY(-10px);

    & > span {
      display: none;
    }
  `};
`;

const ItemMonth = styled.span`
  position: relative;
  ${Font.circular}
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  display: block;
  margin: 0 0 16px;

  ${media.mobile`
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    margin-bottom: 8px;
    position: relative;
    left: 0;
    top: 0;
  `}
`;

const ItemTitle = styled.h3`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  font-weight: 500;
  margin: 0 0 8px;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 0;
  `}
`;

const Description = styled.p`
  ${Font.dutch}
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  margin: 0;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}
`;

export default class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.elements = [];
    this.markers = [];
    this.fills = [];
    this.scene = null;
    this.imageScene = null;
    this.imageWrapper = null;
    this.image = null;
  }

  componentDidUpdate(prevProps) {
    if (this.props.controller && !prevProps.controller) {
      this.initializeAnimation();
      // Initialize it, then re-initialize after fonts loaded
      this.timeout = setTimeout(() => {
        this.initializeAnimation();
      }, 150);
      $(window).on("resize", () => {
        this.initializeAnimation();
      });
    }
  }

  componentWillUnmount() {
    $(window).off("resize");
    clearTimeout(this.timeout);
  }

  initializeAnimation() {
    let sceneDuration = $(this.sceneElement).outerHeight(true);
    // Destory all ongoing scenes.
    if (this.scene) {
      this.scene.destroy(true);
    }

    if (this.imageScene) {
      this.imageScene.destroy(true);
    }

    if (this.itemScenes) {
      this.itemScenes.forEach(scene => {
        scene.destroy(true);
      });
    }

    if (ScrollMagic && this.image && this.imageWrapper) {
      this.scene = new ScrollMagic.Scene({
        triggerElement: this.sceneElement,
        triggerHook: 0.5,
        duration: sceneDuration,
        offset: 0,
      })
        .setTween(this.timelineTween())
        .addTo(this.props.controller);

      this.itemScenes = this.elements.map((item, i) => {
        let duration = $(item).outerHeight(true);
        return new ScrollMagic.Scene({
          triggerElement: item,
          triggerHook: 0.5,
          duration: duration,
          offset: 0,
        })
          .setTween(this.itemTween(item, i, this.elements.length))
          .addTo(this.props.controller);
      });

      this._initializeImageScene();
    }
  }

  _initializeImageScene() {
    if (atLeast.tablet()) {
      let duration =
        $(this.imageWrapper).innerHeight() - $(this.image).outerHeight() - 165;
      this.imageScene = new ScrollMagic.Scene({
        triggerElement: this.imageWrapper,
        duration: duration,
        offset: 165,
      })
        .setPin(".timeline__circle")
        .addTo(this.props.controller);
    }
  }

  itemTween(item, i, elementsCount) {
    var timeline = gsap.timeline();
    var marker = this.markers[i];
    var markerFill = this.fills[i];

    timeline
      .set(item, {
        css: {
          opacity: 0,
        },
      })
      .fromTo(
        marker,
        {
          css: {
            borderColor: "#ced5e1",
            scale: 1,
          },
        },
        {
          duration: 0.01,
          css: {
            borderColor: "#142b6f",
            scale: 1,
          },
        },
      )
      .set(item, {
        css: {
          opacity: 1.0,
        },
      })
      .fromTo(
        markerFill,
        {
          css: {
            scale: 1,
            opacity: 0,
          },
        },
        {
          duration: 0.01,
          css: {
            scale: 1,
            color: "#142b6f",
            opacity: 1,
          },
        },
      )
      .to(marker, { duration: 0.5 })
      .to(marker, {
        duration: 0.7,
        css: {
          scale: 1,
        },
        ease: "power1.easeOut",
      })
      .to(marker, {
        duration: 0.7,
        css: {
          scale: 1,
        },
        ease: "power1.easeIn",
      });

    if (i === elementsCount - 1) {
      timeline.set(".timeline__item__last-marker", {
        css: {
          display: "block",
        },
      });
    }
    return timeline;
  }

  timelineTween() {
    return gsap.fromTo(
      ".timeline__line",
      {
        css: {
          height: "0",
        },
        ease: "none",
      },
      {
        duration: 10,
        css: {
          height: "100%",
        },
        ease: "none",
      },
    );
  }

  imageTween() {
    let wrapper = $(this.imageWrapper);
    let image = $(this.image);
    let top = wrapper.innerHeight() - image.outerHeight() - 165;
    return gsap.fromTo(
      ".timeline__image,.timeline__circle",
      {
        css: {
          y: "0",
        },
        ease: "none",
      },
      {
        duration: 1,
        css: {
          y: `${top > 0 ? top : 0}px`,
        },
        ease: "none",
      },
    );
  }

  render() {
    let { timelineHeading, timelineSubhead, image, data } = this.props;

    return (
      <TimeLineWrapper>
        <TimelineRow>
          <ImageArea ref={r => (this.imageWrapper = r)}>
            <CircleWrapper>
              <TimeLineImage
                className="timeline__image"
                ref={r => (this.image = r)}
              >
                <Img
                  className="d-sm-none"
                  fluid={image.mobile}
                  loading="eager"
                  alt={image.description}
                  id="timeline_row_image-area_wrapper_image_mobile"
                  style={{
                    width: "470px",
                    height: "470px",
                    position: "absolute",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
                <Img
                  className="d-none d-sm-block"
                  fluid={image.desktop}
                  loading="eager"
                  alt={image.description}
                  id="timeline_row_image-area_wrapper_image_desktop"
                  style={{
                    width: "470px",
                    height: "470px",
                    position: "absolute",
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
              </TimeLineImage>
            </CircleWrapper>
          </ImageArea>

          <TimeLineArea>
            <TimelineIntroHeader
              dangerouslySetInnerHTML={{
                __html: stripWrappingParagraphTag(
                  timelineHeading.childMarkdownRemark.html,
                ),
              }}
            ></TimelineIntroHeader>
            <TimelineIntroSubhead>{timelineSubhead}</TimelineIntroSubhead>

            <TimeLineContent ref={r => (this.sceneElement = r)}>
              <Line />
              {data.map((item, i) => {
                let shortname = "point-" + i;
                return (
                  <React.Fragment key={shortname}>
                    <TimelineItem
                      index={i}
                      className={"timeline__item " + shortname}
                      ref={r => (this.elements[i] = r)}
                    >
                      <ItemMarker
                        index={i}
                        ref={r => (this.markers[i] = r)}
                        className={`timeline__item__marker ${shortname}-marker`}
                      />
                      <ItemFill
                        index={i}
                        ref={r => (this.fills[i] = r)}
                        className={`timeline__item__fill ${shortname}-marker-fill`}
                      />
                      <ItemContent index={i} hasMonth={!!item.month}>
                        <ItemMonth index={i} className="timeline__item__month">
                          {item.month}
                        </ItemMonth>
                        <ItemTitle index={i}>{item.title}</ItemTitle>
                        <Description index={i}>{item.description}</Description>
                      </ItemContent>
                    </TimelineItem>
                    {i === data.length - 1 && (
                      <LastMarker className="timeline__item__last-marker" />
                    )}
                  </React.Fragment>
                );
              })}
              {this.props.children}
            </TimeLineContent>
          </TimeLineArea>
        </TimelineRow>
      </TimeLineWrapper>
    );
  }
}
