import React from "react";
import styled from "styled-components";
import { rem, Font, media, Color, atMost } from "../../utils/style";
import Container from "../Container";
import $ from "jquery";
import Row from "../Row";
import CarouselArticle from "../product/CarouselArticle";
import RitualButton from "../global/RitualButton";
import { CarouselContainer, CarouselChild } from "../Carousel.New";
import Text from "../Text";

const FeaturedArticlesWrapper = styled.section.attrs({
  "aria-labelledby": "featured-articles-headline",
})`
  overflow: hidden;
  padding: 0 !important;
  position: relative;
`;

const StyledContainer = styled(Container)`
  z-index: 100;
  position: relative;
`;

const ArticleHeader = styled.div.attrs({
  className: "col-12",
})`
  margin-bottom: 56px;

  ${media.mobile`
    margin-bottom: 40px;
  `};
`;

const ArticlesDescription = styled.div`
  text-align: center;
`;

const Headline = styled.h2.attrs({
  id: "featured-articles-headline",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(48)};
  line-height: ${rem(54)};
  font-weight: 500;
  letter-spacing: -1.4px;
  text-align: center !important;
  margin: 0 0 24px;

  ${media.tablet`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin-bottom: 16px;
    letter-spacing: -0.4px;
  `}
`;

const SubHead = styled.div`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  margin: 0;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}
`;

const CarouselColumn = styled.div`
  padding: 0 !important;

  .slider-list {
    touch-action: initial !important;
  }

  ${media.mobile`
    .slider-list {
      li {
        a {
          display: flex !important;
          justify-content: center !important;
        }
      }
    }
  `};

  .container {
    padding: 0 !important;

    ${media.mobile`
      padding: 0 20px !important;
    `};
  }
`;

const LinkWrapper = styled.div`
  position: relative;
  text-align: center;
  padding: 56px 0 120px;
  z-index: 2;

  ${media.tablet`
    padding: 40px 0 80px;
  `};
`;

export default class FeaturedArticles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: null,
    };
  }

  componentDidMount() {
    this.setVisibleSlides();
    $(window).on(
      "resize.featuredArticlesCarousel",
      this.setVisibleSlides.bind(this),
    );
  }

  componentWillUnmount() {
    $(window).off("resize.featuredArticlesCarousel");
  }

  setVisibleSlides() {
    this.setState({
      isMobile: atMost.mobile(),
    });
  }

  render() {
    let { productSku, articles, children, showCount } = this.props;
    let { isMobile } = this.state;

    return (
      <FeaturedArticlesWrapper>
        <StyledContainer>
          <Row id="featured-articles_container_row-0">
            <ArticleHeader>
              <ArticlesDescription>
                <Headline>
                  <Text
                    id="home.article.headline"
                    defaultMessage="Essential Reading"
                  />
                </Headline>
                <SubHead>
                  <Text
                    id="home.article.subhead"
                    defaultMessage="We're committed to evidence-based products and always question
                  the status quo."
                  />
                </SubHead>
              </ArticlesDescription>
            </ArticleHeader>
          </Row>
          <Row id="featured-articles_container_row-1">
            <CarouselColumn
              className={
                showCount === 3 ? "col-12" : "col-12 col-lg-10 offset-lg-1"
              }
            >
              <CarouselContainer
                showButtons={isMobile}
                disableCarousel={!isMobile}
              >
                {articles.map((article, i) => {
                  return (
                    <CarouselChild key={i} index={i}>
                      <CarouselArticle
                        key={"article-" + i}
                        productSku={productSku}
                        article={article}
                        showCount={showCount}
                        className={
                          i === 0
                            ? "first"
                            : i === articles.length - 1
                            ? "last"
                            : ""
                        }
                      />
                    </CarouselChild>
                  );
                })}
              </CarouselContainer>
            </CarouselColumn>
          </Row>
        </StyledContainer>

        <LinkWrapper>
          <RitualButton
            to="/articles"
            title="See All Articles"
            className="bordered product-articles__btn__see-all"
          >
            <Text
              id="home.article.see-all-cta"
              defaultMessage="See All Articles"
            />
          </RitualButton>
        </LinkWrapper>
        {children}
      </FeaturedArticlesWrapper>
    );
  }
}
