import React from "react";
import styled from "styled-components";

// Components
import Container from "../Container";
import Row from "../Row";
import IngredientCard from "./IngredientCard";
import SupplementFacts from "./SupplementFacts";
import Carousel from "../Carousel";
import IngredientsOverviewSlide from "./IngredientsOverviewSlide";
import Text from "../Text";

// Utils
import { Font, Color, rem, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import metrics from "../../utils/metrics";
import MagicLink from "../MagicLink";

// Styled Elements
const Wrapper = styled(Container).attrs({
  role: "section",
  "aria-labelledby": "ingredients-overeview-header",
})`
  margin-bottom: 80px;

  ${responsive.md`
    margin-bottom: 120px;
  `};
`;

const HeaderColumn = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 56px;
  `};
`;

const Header = styled.h2.attrs({
  id: "ingredients-overeview-header",
})`
  ${Font.circular};
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  font-weight: 500;
  letter-spacing: -0.4px;
  text-align: center !important;
  color: ${Color.ritualBlue};
  margin: 0 auto 16px;

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1px;
    margin: 0 0 24px;
  `}
`;

const Subhead = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  color: ${Color.ritualBlue};
  margin: 0;

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}

  span {
    font: inherit;
  }
`;

const IngredientsRow = styled(Row)``;

const ArticleLinkColumn = styled.div`
  text-align: center;
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${responsive.md`
    margin-top: 56px;
  `};
`;

const ArticleLinkHeader = styled.h2`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 500;
  text-align: center !important;
  margin: 0;
  padding: 0;

  ${responsive.md`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
    max-width: 454px;
  `};
`;

const SupplementLink = styled.span`
  border-bottom: 1px solid ${Color.ritualBlue};
  cursor: pointer;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.56;
  }
`;

const ExpandIngredients = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.16);
  margin-top: 8px;
  padding-top: 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${responsive.sm`
    margin-top: 0;
  `};

  ${responsive.md`
    margin-top: 16px;
  `};

  button {
    appearance: none;
    background: none;
    border: 0;
    padding: 0;
    margin: 0;

    span {
      ${Font.circular}
      color: ${Color.ritualBlue};
      font-size: ${rem(14)};
      line-height: ${rem(24)};
      font-weight: 500;
      margin: 0 8px 0 0;
    }

    svg {
      width: 14px;
      height: 8px;
    }
  }
`;

const ArticleLink = styled(MagicLink)`
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  display: inline-block;
  border-bottom: 2px solid ${Color.ritualBlue};
  margin: 24px 0 0;
`;

export default class IngredientsOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      factsOpen: false,
      cardLimits: {
        small: 4,
        large: 6,
      },
      expandedView: false,
      deviceState: "large",
      activeSlideIndex: -1,
      carouselControls: true,
    };

    this.resizeHandler = this.resizeHandler.bind(this);
    this.openCarousel = this.openCarousel.bind(this);
    this.clickEvent = this.clickEvent.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeHandler);
    this.resizeHandler();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  }

  resizeHandler() {
    const width = window.innerWidth;
    const newDeviceState = width >= 960 ? "large" : "small";

    if (newDeviceState !== this.state.deviceState) {
      this.setState({
        deviceState: newDeviceState,
        carouselControls: newDeviceState === "large",
      });
    }
  }

  handlePopup() {
    this.factsPopup.open();
  }

  toggleView() {
    this.setState(
      prevState => ({
        expandedView: !prevState.expandedView,
      }),
      () => {
        if (this.state.deviceState === "small" && !this.state.expandedView) {
          const element = document.getElementById(
            `ingredient-card-${this.state.cardLimits["small"] - 1}`,
          );
          const newScrollPosition =
            element.getBoundingClientRect().top +
            document.documentElement.scrollTop;

          window && window.scrollTo(0, newScrollPosition);
        }
      },
    );
  }

  hideCarousel() {
    this.setState({
      activeSlideIndex: -1,
    });
  }

  openCarousel(index) {
    this.setState({
      activeSlideIndex: index,
    });
  }

  clickEvent(ingredient) {
    metrics.track("Ingredient Card Clicked", {
      name: ingredient.name,
      location: "Ingredient List",
      nonInteraction: false,
    });
  }

  generateCarouselSlides() {
    const { ingredients, product } = this.props;
    return ingredients.map((ingredient, index) => {
      return (
        <IngredientsOverviewSlide ingredient={ingredient} product={product} />
      );
    });
  }

  render() {
    const {
      product,
      ingredients,
      ingredientsSubheader,
      somethingMissingTitle,
    } = this.props;
    const {
      activeSlideIndex,
      cardLimits,
      expandedView,
      deviceState,
      carouselControls,
    } = this.state;

    const visibleCards = expandedView
      ? ingredients.length
      : cardLimits[deviceState];

    const caretType = expandedView ? "CaretUpRounded" : "CaretDownRounded";
    const CaretDown = Icons[caretType];

    return (
      <Wrapper>
        <Row>
          <HeaderColumn>
            <Header>
              <Text
                id="product.ingredients.header"
                defaultMessage="{n} Traceable Ingredients"
                values={{ n: ingredients.length }}
              />
            </Header>
            <Subhead>
              {ingredientsSubheader.childMarkdownRemark.rawMarkdownBody}{" "}
              <SupplementLink onClick={this.handlePopup.bind(this)}>
                <Text
                  id="product.ingredients.view-facts"
                  defaultMessage="View Supplement Facts"
                />
              </SupplementLink>
            </Subhead>
          </HeaderColumn>
        </Row>

        <IngredientsRow>
          {ingredients.slice(0, visibleCards).map((ingredient, i) => {
            return (
              <IngredientCard
                id={`ingredient-card_${i}`}
                ingredient={ingredient}
                product={product}
                key={"ingredient-" + i}
                handleClick={() => {
                  this.openCarousel(i);
                  this.clickEvent(ingredient);
                }}
              />
            );
          })}
        </IngredientsRow>

        <Row>
          <ExpandIngredients className="col-12">
            <button onClick={this.toggleView.bind(this)}>
              <span>
                {expandedView ? (
                  <Text
                    id="product.ingredients.see-less"
                    defaultMessage="See Less"
                  />
                ) : (
                  <Text
                    id="product.ingredients.see-all"
                    defaultMessage="See All {n}"
                    values={{ n: ingredients.length }}
                  />
                )}
              </span>
              <CaretDown />
            </button>
          </ExpandIngredients>
        </Row>

        <Row>
          <ArticleLinkColumn className="col-12 col-sm-8 offset-sm-2">
            <ArticleLinkHeader>{somethingMissingTitle}</ArticleLinkHeader>
            <ArticleLink
              to="/articles/what-vitamins-should-i-take"
              state={{ productSku: product.sku }}
            >
              <Text
                id="product.ingredients.link-how-much"
                defaultMessage="Read Why Less Is More"
              />
            </ArticleLink>
          </ArticleLinkColumn>
        </Row>

        {product.supplementFacts && (
          <SupplementFacts
            supplementFacts={product.supplementFacts}
            ref={r => (this.factsPopup = r)}
          />
        )}

        {activeSlideIndex >= 0 && (
          <Carousel
            activeSlideIndex={activeSlideIndex}
            slides={this.generateCarouselSlides()}
            close={this.hideCarousel.bind(this)}
            controls={carouselControls}
            repeat={true}
          />
        )}
      </Wrapper>
    );
  }
}
