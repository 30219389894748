import React from "react";
import styled from "styled-components";
import Accordion from "./Accordion";
import Row from "../Row";
import { Font, Color, rem, media } from "../../utils/style";
import Text from "../Text";

const Wrapper = styled.section.attrs({
  className: "container",
  "aria-labelledby": "QAOverview-title",
})`
  margin-bottom: ${p => (p.additionalMargin ? "120px" : "0")};

  ${media.mobile`
    margin-top: 80px;
    margin-bottom: ${p => (p.additionalMargin ? "64px" : "0")};
  `};
`;

const QAIntroColumn = styled.div.attrs({
  className: "col-12 col-md-8 offset-md-2",
})`
  margin-bottom: 56px;
  z-index: 2;

  ${media.mobile`
    margin-bottom: 40px;
  `};
`;

const QAIntroTitle = styled.h2.attrs({
  id: "qa-overview-title",
})`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(48)};
  line-height: ${rem(54)};
  font-weight: 500;
  text-align: center;
  letter-spacing: -1.4px;
  text-align: center !important;
  margin: 0 0 24px;

  ${media.tablet`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
  `}
`;

const QAIntroSubheader = styled.p`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  text-align: center;
  letter-spacing: 0;
  margin: 0;

  ${media.tablet`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
  }
`;

const QuestionsColumn = styled.div.attrs({
  className: "col-12 col-md-6 offset-md-3",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
  padding-left: 0 !important;
  padding-right: 0 !important;

  ${media.mobile`
    margin-top: 40px;
    flex-direction: column;
    padding-left: 24px !important;
    padding-right: 24px !important;
  `};
`;

const MoreQuestionsArea = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MoreQuestions = styled.p`
  ${Font.dutch}
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  text-align: center;
  margin: 0;

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 0.56;
    }
  }

  ${media.mobile`
    margin-bottom: 16px;
  `}
`;

export default class QAOverview extends React.Component {
  render() {
    let { questions, additionalMargin } = this.props;
    let questionData = JSON.parse(
      questions.childMarkdownRemark.rawMarkdownBody,
    );

    let accordianData = questionData.map(q => {
      return {
        header: q.question,
        body: q.answer,
      };
    });

    return (
      <Wrapper additionalMargin={additionalMargin}>
        <Row>
          <QAIntroColumn>
            <QAIntroTitle>
              <Text
                id="product.qa-overview.title"
                defaultMessage="We Want You to Ask Questions"
              />
            </QAIntroTitle>
            <QAIntroSubheader>
              <Text
                id="product.qa-overview.subheader"
                defaultMessage="Seriously. Our team of vitamin experts and scientists has answers."
              />
            </QAIntroSubheader>
          </QAIntroColumn>
        </Row>

        <Row>
          <Accordion items={accordianData} />
        </Row>

        <Row>
          <QuestionsColumn>
            <MoreQuestionsArea>
              <MoreQuestions>
                <Text
                  id="product.qa-overview.more-questions"
                  defaultMessage="More questions? Find answers on our {link}."
                  values={{
                    link: (
                      <a href="https://help.ritual.com">
                        <Text
                          id="product.qa-overview.more-questions-link"
                          defaultMessage="FAQs page"
                        />
                      </a>
                    ),
                  }}
                />
              </MoreQuestions>
            </MoreQuestionsArea>
          </QuestionsColumn>
        </Row>
      </Wrapper>
    );
  }
}
