import React from "react";
import styled from "styled-components";
import { media, atLeast } from "../../utils/style";
import PressBar from "./PressBar";
import PressCarousel from "./PressCarousel";
import $ from "jquery";

const PressModuleWrapper = styled.section.attrs({
  id: "product-press-module",
})`
  ${media.mobile`
    margin: 40px auto;
  `};
`;

export default class PressModule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      atLeastTablet: true,
    };
  }

  componentDidMount() {
    this.timeout = setTimeout(this.handleResize.bind(this), 200);
    $(window).on("resize.pressModule", this.handleResize.bind(this));
  }

  componentWillUnmount() {
    if (this.timeout) clearTimeout(this.timeout);
    $(window).off("resize.pressModule");
  }

  handleResize() {
    this.setState({ atLeastTablet: atLeast.tablet() });
  }

  render() {
    const { atLeastTablet } = this.state;
    const { quotes, background } = this.props;

    return (
      <PressModuleWrapper aria-label="Press">
        {atLeastTablet ? (
          <PressBar quotes={quotes.slice(0, 3)} background={background} />
        ) : (
          <PressCarousel slides={quotes.slice(0, 3)} background={background} />
        )}
      </PressModuleWrapper>
    );
  }
}
