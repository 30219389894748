import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

// Utils
import { Font, Color, rem, responsive } from "../../utils/style";
import { stripWrappingParagraphTag } from "../../utils/contentful";

// Styled Elements
const PillSectionWrapper = styled.section.attrs({
  "aria-labelledby": "pill-section-title",
})`
  background-color: rgba(242, 241, 245, 0.56);

  padding: 64px 0;
  margin-bottom: 80px;

  ${responsive.sm`
    padding: 80px 0;
  `}

  ${responsive.md`
    padding: 120px 0;
    margin-bottom: 120px;
  `};
`;

const PillSectionContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding: 0;
  `}

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${responsive.sm`
    flex-direction: row;
  `}
`;

const PillSectionColumn = styled.div`
  position: relative;
  width: 100%;
  margin-right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  ${responsive.sm`
    align-items: flex-start;
    width: 340px;
    min-height: 340px;
    margin-right: 20px;
  `}

  ${responsive.md`
    width: 400px;
    min-height: 400px;
    margin-right: 64px;
  `}

  ${responsive.lg`
    width: 470px;
    min-height: 470px;
    margin-right: 120px;
  `}

  &:last-of-type {
    margin-right: 0;
  }
`;

const PillSectionTitle = styled.h2.attrs({
  id: "pill-section-title",
})`
  margin: 0;

  ${Font.circular};
  color: ${Color.ritualBlue};
  font-weight: 500;

  letter-spacing: -0.4px;
  font-size: ${rem(30)};
  line-height: ${rem(36)};

  max-width: 260px;
  text-align: center !important;

  ${responsive.sm`
    text-align: left !important;
    margin-bottom: 32px;
  `};

  ${responsive.md`
    margin-bottom: 40px;
    letter-spacing: -1.4px;
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    max-width: 100%;
  `};

  em,
  i {
    ${Font.dutch};
  }
`;

const PillMobileImage = styled(Img)`
  width: 280px;
  height: 280px;
  margin-bottom: 8px;

  ${responsive.sm`
    display: none !important;
  `};
`;

const PillDesktopImageWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PillDesktopImage = styled(Img)`
  display: none;

  ${responsive.sm`
    display: block;
  `}
`;

const ItemWrapper = styled.div`
  margin-bottom: 24px;

  ${responsive.md`
    margin-bottom: 32px;
  `};

  &:last-child {
    margin: 0;
  }
`;

const ItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ItemHeaderText = styled.h3`
  ${Font.circular};
  scolor: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};
`;

const ItemBody = styled.div`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  padding: 8px 0 0 36px;

  ${responsive.sm`
    padding-left: 44px;
  `}

  ${responsive.md`
    padding-left: 40px;
  `}
`;

const Bullet = styled.div`
  background-color: ${Color.ritualBlue};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 20px;
  width: 20px;
  margin-right: 16px;

  ${responsive.sm`
    margin-right: 24px;
  `}

  ${responsive.md`
    height: 24px;
    width: 24px;
    margin-right: 16px;
  `}

  span {
    ${Font.circular};
    color: ${Color.white};
    font-weight: 500;
    letter-spacing: 0px;

    font-size: ${rem(12)};
    line-height: ${rem(20)};

    ${responsive.md`
      font-size: ${rem(14)};
      line-height: ${rem(24)};
    s`}
  }
`;

export default class PillSection extends React.Component {
  render() {
    const {
      pillSectionTitle,
      pillSectionImage,
      pillSectionAccordion,
      className,
    } = this.props;

    let title = stripWrappingParagraphTag(
      pillSectionTitle.childMarkdownRemark.html,
    );

    return (
      <PillSectionWrapper className={className}>
        <PillSectionContainer>
          <PillSectionColumn>
            <PillSectionTitle
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
            <PillMobileImage
              fluid={pillSectionImage.fluid}
              alt={pillSectionImage.description}
              loading="eager"
            />
            <div>
              {pillSectionAccordion.map((item, i) => {
                return (
                  <ItemWrapper key={i}>
                    <ItemHeader>
                      <Bullet>
                        <span>{i + 1}</span>
                      </Bullet>
                      <ItemHeaderText>{item.title}</ItemHeaderText>
                    </ItemHeader>
                    <ItemBody
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </ItemWrapper>
                );
              })}
            </div>
          </PillSectionColumn>
          <PillSectionColumn>
            <PillDesktopImageWrapper>
              <PillDesktopImage
                fluid={pillSectionImage.fluid}
                alt={pillSectionImage.description}
                loading="eager"
              />
            </PillDesktopImageWrapper>
          </PillSectionColumn>
        </PillSectionContainer>
      </PillSectionWrapper>
    );
  }
}
