import React from "react";
import styled from "styled-components";
import { PressLogos } from "../../utils/svg";
import { Font, rem } from "../../utils/style";
import MagicLink from "../MagicLink";
import { CarouselContainer, CarouselChild } from "../Carousel.New";

const CarouselWrapper = styled.div.attrs({
  id: "press-carousel_wrapper",
})`
  padding: 0;
  margin-bottom: 0 !important;

  p,
  span {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  div[aria-hidden="true"] {
    opacity: 0.75;
  }
  *,
  &:hover {
    outline: none !important;
  }
`;

const PressWrapper = styled.div.attrs(p => ({
  id: `press-carousel_wrapper_slide-${p.index}`,
}))`
  background: rgba(242, 241, 245, 0.56);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 15px;
  padding: 32px;
  text-align: center;
  height: 165px;
`;

const PressIcon = styled.div.attrs(p => ({
  id: `press-carousel_wrapper_slide-${p.index}_icon`,
}))`
  margin-bottom: 8px;
`;

const PressQuote = styled.p.attrs(p => ({
  id: `press-carousel_wrapper_slide-${p.index}_icon_quote`,
}))`
  ${Font.circular}
  font-size: ${rem(14)};
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 0;
  max-width: 231px;
`;

export default class PressCarousel extends React.Component {
  render() {
    const { slides } = this.props;
    return (
      <div id="press-carousel" className="w-100">
        <CarouselWrapper>
          <CarouselContainer showButtons>
            {slides.map((slide, i) => {
              const PressSvg = PressLogos[slide.icon];
              if (slide.link) {
                return (
                  <CarouselChild index={i} key={i}>
                    <PressWrapper index={i}>
                      <PressIcon index={i}>
                        <MagicLink
                          id={`press-carousel_wrapper_slide-${i}_icon_link`}
                          href={slide.link}
                          ariaLabel={slide.author}
                          rel="noopener noreferrer"
                        >
                          <PressSvg style={{ display: "inline-block" }} />
                          <PressQuote>“{slide.quote.quote}”</PressQuote>
                        </MagicLink>
                      </PressIcon>
                    </PressWrapper>
                  </CarouselChild>
                );
              }
              return (
                <CarouselChild index={i} key={i}>
                  <PressWrapper index={i}>
                    <PressIcon index={i}>
                      <PressSvg />
                    </PressIcon>
                    <PressQuote>“{slide.quote.quote}”</PressQuote>
                  </PressWrapper>
                </CarouselChild>
              );
            })}
          </CarouselContainer>
        </CarouselWrapper>
      </div>
    );
  }
}
