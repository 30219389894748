import React from "react";
import styled, { keyframes } from "styled-components";
import { Font, Color, rem, media } from "../../utils/style";
import { Icons } from "../../utils/svg";
import { lockScroll } from "../../utils/lockScroll";
import Text from "../Text";

const OpenAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const PopupWrapper = styled.div.attrs({ className: "ajs_supplement-facts" })`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  animation: ${OpenAnimation} 0.2s ease-in-out 0s normal forwards;
  display: ${p => (p.open ? "flex" : "none")};
  flex-direction: column;
  justify-content: flex-start;
  backdrop-filter: blur(10px);
`;

const CloseWrapper = styled.div.attrs({
  className: "ajs_supplement-facts_close-wrapper",
})`
  min-height: 72px;

  ${media.mobile`
    min-height: 48px;
  `};
`;

const CloseButton = styled.span.attrs({
  className: "ajs_supplement-facts_close-wrapper_button",
})`
  display: block;
  width: 48px;
  height: 48px;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.mobile`
    top: 0;
    right: 0;
  `};

  &:hover {
    svg {
      opacity: 0.56;
    }
  }

  span {
    position: relative;
    width: 16px;
    height: 16px;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 200ms ease-in-out;
    color: ${Color.ritualBlue};

    g {
      fill: currentColor;
    }
  }
`;

const TableWrapper = styled.div.attrs({
  className: "ajs_supplement-facts_table-wrapper",
})`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: calc(100% - 72px);

  ${media.mobile`
    height: calc(100% - 48px);
  `}

  & > div {
    position: relative;
    background-color: ${Color.white};
    box-sizing: border-box !important;
    max-height: calc(100vh - 40px - 72px);
    overflow: auto;
    padding: 20px;
    box-shadow: 0 0 2px rgba(${Color.ritualBlue},0.56);

    ${media.mobile`
      padding: 0;
      width: 100%;
      max-height: 100%;
    `}
  }

  table {
    table-layout: fixed;
    width: 650px;
    text-align: left;
    background-color: ${Color.white};

    ${media.tablet`
      width: 560px;
    `}

    ${media.mobile`
      width: calc(100% - 24px);
      margin: 12px;
      margin-bottom: calc(12px + env(safe-area-inset-bottom));
    `}

    thead {
      border: 1px solid ${Color.ritualBlue};

      tr {
        th {
          &:first-child {
            width: 50%;
          }
        }
      }
    }

    thead,
    tbody {

      tr {
        &.outline {
          border: 1px solid ${Color.ritualBlue};
        }

        &.no-top {
          border-top: none;
        }

        &.no-bottom {
          border-bottom: none;
        }
      }

      tr,
      td,
      th {
        ${Font.circular}
        font-size: ${rem(14)};
        line-height: ${rem(18)};
        font-weight: 500;
        text-align: left;
        padding: 4px 6px;
        word-break: break-word;

        ${media.mobile`
          font-size: ${rem(12)};
          line-height: ${rem(16)};
          padding: 2px 4px;
        `}

        &.title {
          font-size: ${rem(36)};
          line-height: ${rem(40)};
          font-weight: 700;
        }

        &.small {
          font-size: ${rem(12)};
          line-height: ${rem(16)};
        }

        &.attribution {
          font-weight: 300;
          font-size: ${rem(10)};
          line-height: ${rem(12)};
        }

        &.last {
          font-weight: 300;
          border: none !important;
          font-size: ${rem(12)};
          line-height: ${rem(16)};
        }

        span {
          display: block;
          font-size: ${rem(12)};
          line-height: ${rem(16)};
          padding-left: 16px;
          font-weight: 300;

          &.indent {
            display: inline;
          }

          &.light {
            display: inline;
            padding: 0;
            margin: 0;
          }

          ${media.mobile`
            padding-left: 8px;
          `}
        }
      }

      td + td,
      th {
        text-align: center;
      }
    }

    thead {
      tr {
        &.bottom-line,
        &:last-child {
          td,
          th {
            border-bottom: 18px solid ${Color.ritualBlue};
          }
        }
      }
    }

    tbody {
      tr {

        &:first-child {
          td,
          th {
            font-weight: 500;
          }
        }

        &.bottom-line {
          td,
          th {
            border-bottom: 9px solid ${Color.ritualBlue};
          }
        }

        &:first-child {
          td,
          th {
            border-bottom: 4px solid ${Color.ritualBlue};
          }
        }
      }
    }
  }
`;

export default class SupplementFacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleEscDown = this.handleEscDown.bind(this);
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleEscDown);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscDown);
  }

  handleEscDown(e) {
    if (e.keyCode === 27) {
      this.close();
    }
  }

  open() {
    this.setState({
      open: true,
    });
  }

  close() {
    this.setState({
      open: false,
    });
  }

  renderTable() {
    let { supplementFacts } = this.props;

    if (!supplementFacts) return null;

    return this.generateTable(supplementFacts);
  }

  generateTable(data) {
    return (
      <table
        className="ajs_supplement-facts_table-wrapper_table"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <thead>
          <tr>
            <th />
            <th />
            <th />
          </tr>
          <tr>
            <th colSpan="3" className="title">
              <Text
                id="product.supplement-facts.heading"
                defaultMessage="Supplement Facts"
              />
            </th>
          </tr>
          <tr>
            <td colSpan="3">
              <Text
                id="product.supplement-facts.serving-size"
                defaultMessage="Serving Size:"
              />{" "}
              <span className="light">{data.servingSize}</span>
            </td>
          </tr>
          <tr>
            <td colSpan="3">
              <Text
                id="product.supplement-facts.serving-pr-con"
                defaultMessage="Servings Per Container:"
              />{" "}
              <span className="light">{data.servingsPerContainer}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr className="outline">
            <td />
            <th>
              <Text
                id="product.supplement-facts.amount-per-serve"
                defaultMessage="Amount Per Serving"
              />
            </th>
            <th>{data?.labels?.dv || "% DV"}</th>
          </tr>
          {// Generate Nutrients
          this.generateIngredientRow(data.nutrients, "nutrients")}
          {// Generate Ingredients
          this.generateIngredientRow(data.ingredients, "ingredients")}
          {// Generate Notations
          data.notations &&
            data.notations.map((notation, i, array) => {
              let className = "outline";
              if (i + 1 === array.length) {
                className += " no-top";
              }
              if (array.length > 1 && i + 1 < array.length) {
                className += " no-bottom";
              }
              return (
                <tr key={`notation-${i}`} className={className}>
                  <td className="small" colSpan="3">
                    {notation.label}
                  </td>
                </tr>
              );
            })}
          {data.otherIngredients && (
            <tr>
              <td colSpan="3">
                <Text
                  id="product.supplement-facts.other-ingredients"
                  defaultMessage="Other ingredients:"
                />{" "}
                <span className="light">{data.otherIngredients}</span>
              </td>
            </tr>
          )}
          {data.notationReferences && (
            <tr>
              <td
                className="last"
                colSpan="3"
                dangerouslySetInnerHTML={{ __html: data.notationReferences }}
              />
            </tr>
          )}
          {// Generate Notations
          data.attributions &&
            data.attributions.map((attribution, i, array) => {
              return (
                <tr key={`attribution-${i}`}>
                  <td className="attribution" colSpan="3">
                    {attribution.label}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }

  generateIngredientRow(data, key) {
    if (!data) return null;

    return data.map((item, i, array) => {
      let className = "outline";
      if (i + 1 === array.length) {
        className += " bottom-line";
      }
      return (
        <tr key={`${key}-${i}`} className={className}>
          <td dangerouslySetInnerHTML={{ __html: item.label }} />
          <td>{item.dosage}</td>
          <td>{item.dv}</td>
        </tr>
      );
    });
  }

  render() {
    let { open } = this.state;

    if (this.tableElementWrapper) {
      lockScroll(open, this.tableElementWrapper);
    }

    return (
      <PopupWrapper open={open} onClick={this.close.bind(this)}>
        <CloseWrapper>
          <CloseButton onClick={this.close.bind(this)}>
            <span>
              <Icons.Close />
            </span>
          </CloseButton>
        </CloseWrapper>

        <TableWrapper>
          <div ref={r => (this.tableElementWrapper = r)}>
            {this.renderTable()}
          </div>
        </TableWrapper>
      </PopupWrapper>
    );
  }
}
