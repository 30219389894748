import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

// Services
import intl from "../services/intl";

// Components
import ProductHero from "../components/product/ProductHero";
import IngredientsOverview from "../components/product/IngredientsOverview";
import QAOverview from "../components/product/QAOverview";
import ProductSEO from "../components/seo/Product";
import ValueProps from "../components/product/ValueProps";
import Timeline from "../components/product/Timeline";
import Experts from "../components/product/Experts";
import FeaturedArticles from "../components/home/FeaturedArticles";
import DownloadGuide from "../components/product/DownloadGuide";
import Press from "../components/home/Press";
import PressModule from "../components/home/PressModule";
import Testimonials from "../components/testimonials/Testimonials";
import Reviews from "../components/product/Reviews";
import ScrollMagicController from "../components/ScrollMagicController";
import AggregatedStarRating from "../components/reviews/AggregatedStarRating";
import MostHelpfulReviews from "../components/reviews/MostHelpfulReviews";
import CircleNew from "../components/home/Circle.New";
import PillSection from "../components/product/PillSection";
import StickyProductNav from "../components/global/StickyProductNav";

// Utils
import { responsive } from "../utils/style";
import NameMap from "../utils/nameMap";
import { variation } from "../utils/launchDarkly";
import metrics from "../utils/metrics";
import { trackProductViewed } from "../utils/tracking/product";

// Store
import { connect } from "react-redux";
import productSelectors from "../store/product/selectors";
import { updateFooterUSPNotice } from "../store/navigation/actions";

// Styled Elements
const TestimonialsWrapper = styled.div`
  margin-top: 56px;
  margin-bottom: 56px;
`;

const ValuePropsWrapper = styled.section.attrs({
  "aria-label": "Product Value Propositions",
})`
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 56px;
  `}
`;

const DefaultModuleOrder = ["benefits", "ingredients", "pill"];
const KidsModuleOrder = ["pill", "ingredients", "benefits"];

export class ProductPageTemplate extends React.Component {
  constructor(props) {
    super(props);

    const { mostHelpful } = props.data;

    let {
      name,
      slug,
      sku,
      summary,
      pageTitle,
      pageDescription,
      socialImage,
      stockStatus,
      price,
    } = props.data.allContentfulProduct.edges[0].node;

    const { reviewRatingsMap, reviewCountsMap } = props;

    let productNameMap = NameMap(name);
    let { productId, planId, productType } = props.pageContext;

    this.moduleOrder =
      sku === "EFKIDS90" ? KidsModuleOrder : DefaultModuleOrder;

    this.state = {
      controller: null,
      planId,
      productId,
      productType,
      seo: {
        pagePath: `products/${slug}`,
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
        productAttributes: {
          ratingValue: reviewRatingsMap[productId],
          reviewCount: reviewCountsMap[productId],
          topReview: mostHelpful,
          // Strip non-alphanumeric characters from name
          name: productNameMap.plain,
          description: summary,
          productId,
          sku,
          brand: "Ritual",
          availability: stockStatus,
          condition: "new",
          category:
            "525 - Health & Beauty > Health Care > Fitness & Nutrition > Vitamins & Supplements",
          amount: price.toString(),
          currency: "USD",
          stockStatus,
        },
      },
    };
  }

  productAttributes() {
    let {
      sku,
      name,
      price,
      slug,
      socialImage,
    } = this.props.data.allContentfulProduct.edges[0].node;
    let { planId, productId } = this.state;
    let productAttributes = {
      product_id: productId,
      variant: planId,
      sku: sku,
      name: NameMap(name).plain,
      currency: "USD",
      price: price,
      quantity: 1,
      url: `${process.env.GATSBY_URL}/products/${slug}`,
      image_url: `https:${socialImage.file.url}`,
      nonInteraction: 0,
    };
    return productAttributes;
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Product Page",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: true,
    });

    // TO-DO: Consider a CMS variable instead of hardcoding
    this.props.updateFooterUSPNotice(this.productAttributes().sku === "EFW60");

    const showPressBar = variation("pdp-press-bar");

    this.setState({
      controller: this.controller,
      showPressBar,
    });

    trackProductViewed(this.state.planId, {
      nonInteraction: false,
    });
  }

  componentWillUnmount() {
    this.props.updateFooterUSPNotice(false);

    if (this.controller) {
      this.controller.destroy();
    }
  }

  trackCTAClick(title, location, url, e) {
    e.preventDefault();
    metrics
      .track("CTA Clicked", {
        title: title,
        nonInteraction: false,
        location: location,
      })
      .then(() => {
        window.location.href = url;
      });
  }

  trackProductCTAClick(title, location, e) {
    let event = {
      title: title,
      nonInteraction: false,
      location: location,
    };
    metrics.track("CTA Clicked", event);
  }

  handlePressQuotesData = url => {
    let data = [];
    const nyt = {
      id: "",
      author: "The New York Times",
      icon: "nyt",
      link: "",
      quote: {
        quote: "Vitamins join the ‘clean label’ bandwagon",
      },
    };
    const popSugar = {
      id: "",
      author: "Popsugar",
      icon: "popsugar",
      link: "",
      quote: {
        quote:
          "9 quality essential ingredients backed by research, and 100% transparency on all fronts",
      },
    };
    const forbes = {
      id: "",
      author: "Forbes",
      icon: "forbes",
      link: "",
      quote: {
        quote: "Ritual is reinventing vitamins for women",
      },
    };
    const byrdie = {
      id: "",
      author: "Byrdie",
      icon: "byrdie",
      link: "",
      quote: {
        quote:
          "This Vitamin Company Just Launched The Perfect Multivitamin For Women Over 50",
      },
    };
    const health = {
      id: "",
      author: "Health",
      icon: "health",
      link: "",
      quote: {
        quote:
          "Their prenatal uses a delayed-release nesting capsule to reduce nausea",
      },
    };
    switch (url) {
      case "/products/essential-for-women-multivitamin":
        data.push(nyt, popSugar, forbes);
        break;
      case "/products/essential-multivitamin-for-women-over-50":
        data.push(nyt, byrdie, forbes);
        break;
      case "/products/essential-prenatal-multivitamin":
        data.push(nyt, health, forbes);
        break;
      default:
        data.push(nyt, popSugar, forbes);
    }
    return data;
  };

  render() {
    let { controller, productType, productId, showPressBar } = this.state;
    let product = this.props.data.allContentfulProduct.edges[0].node;
    product = {
      id: productId,
      type: productType,
      simpleName: NameMap(product.name).plain,
      ...product,
    };
    let {
      articles,
      heroBackgroundColor,
      price,
      heroFeaturedImages,
      heroZoomImages,
      headerDescription,
      pressQuotes,
      valuePropIcons,
      valuePropLabels,
      faq,
      ingredientsSubheader,
      somethingMissingTitle,
      doctorGuide,
      timelineHeading,
      timelineSubhead,
      timelinePhoto,
      timelineData,
      experts,
      ingredients,
      sku,
      pillSectionTitle,
      pillSectionImage,
      pillSectionAccordion,
    } = product;

    const {
      mostHelpful,
      mostCritical,
      allContentfulClinical,
    } = this.props.data;

    const { reviewRatingsMap, reviewCountsMap } = this.props;

    const clinicalData =
      allContentfulClinical.edges.length > 0
        ? allContentfulClinical.edges[0].node
        : null;

    let timeline = JSON.parse(timelineData.childMarkdownRemark.rawMarkdownBody);

    const heroButtonCta = intl.t("templates.product.hero-cta", "Add to Cart");
    const expertsTitle = intl.t(
      "templates.product.experts-title",
      "Meet Our In-House Scientists and Expert Council",
    );
    const expertsSubhead = intl.t(
      "templates.product.experts-subhead",
      `We work with a lot of exceptional and thoughtful people—like the scientists, nutritionists, and medical doctors on our Scientific Advisory Board, who help guide our product innovation and clinical research.`,
    );

    let heroContent = {
      buttonPrice: intl.t("product.hero.button-price", "{amount} per month", {
        amount: intl.formatCurrency(price, { round: true }),
      }),
      buttonCta: heroButtonCta,
      featuredImages: heroFeaturedImages,
      zoomImages: heroZoomImages,
      heroBackgroundColor,
    };

    let ingredientsOverviewContent = {
      ingredientsSubheader,
      somethingMissingTitle,
      productType,
    };

    let guide = {
      guideText: intl.t(
        "templates.product.guide-text",
        `Ready to talk to your doctor about {product}?`,
        { product: product.name.name },
      ),
      doctorGuide,
    };

    let productRating = {
      reviewScore: reviewRatingsMap[productId],
      reviewCount: reviewCountsMap[productId],
    };

    let initialReviews = this.props.data.allProductReview.edges.map(
      e => e.node,
    );
    let showTestimonials = sku.includes("EFW");

    let productAttributes = this.productAttributes();

    const { offset } = this.props;

    let url = this.props.path;
    let newPressQuotes = this.handlePressQuotesData(url) || pressQuotes;
    let additionalQAMargin = !showTestimonials && !doctorGuide;

    return (
      <>
        <ProductSEO {...this.state.seo} />

        <StickyProductNav
          sku={product.sku}
          ctaLocation={"Sticky Nav"}
          offsetFromBanner={offset}
          showTopBar={false}
        />

        <ProductHero
          product={product}
          productName={product.name}
          trackCTAClick={this.trackProductCTAClick.bind(this)}
          headerDescription={headerDescription}
          {...productRating}
          {...heroContent}
          clinicalData={clinicalData}
        />

        {showPressBar && (
          <PressModule
            quotes={newPressQuotes}
            background={"rgba(242, 241, 245, 0.56)"}
          />
        )}
        <ValuePropsWrapper>
          <ValueProps valueProps={valuePropLabels} icons={valuePropIcons} />
        </ValuePropsWrapper>

        {this.moduleOrder.map(module => {
          if (module === "benefits") {
            // Render the Benefits Module
            return (
              <React.Fragment key={module}>
                <Timeline
                  timelineHeading={timelineHeading}
                  timelineSubhead={timelineSubhead}
                  image={timelinePhoto}
                  data={timeline}
                  controller={controller}
                />

                <ScrollMagicController
                  ref={r => {
                    r && (this.controller = r.controller);
                  }}
                />
              </React.Fragment>
            );
          }
          // Render the Ingredients Module
          if (module === "ingredients") {
            return (
              <IngredientsOverview
                key={module}
                {...ingredientsOverviewContent}
                ingredients={ingredients}
                product={product}
              />
            );
          }
          // Render the PillSection Module
          if (module === "pill") {
            return (
              <PillSection
                key={module}
                pillSectionTitle={pillSectionTitle}
                pillSectionImage={pillSectionImage}
                pillSectionAccordion={pillSectionAccordion}
              />
            );
          }
        })}

        <QAOverview questions={faq} additionalMargin={additionalQAMargin} />

        {doctorGuide && (
          <DownloadGuide
            trackCTAClick={this.trackCTAClick.bind(this)}
            {...guide}
          />
        )}

        {showTestimonials && (
          <TestimonialsWrapper>
            <Testimonials />
          </TestimonialsWrapper>
        )}

        <FeaturedArticles
          productSku={product.sku}
          articles={articles.slice(0, 2)}
        >
          <CircleNew className="bottom right featuredArticles" />
        </FeaturedArticles>

        <Experts
          arrowsEnabled={true}
          expertsTitle={expertsTitle}
          expertsSubhead={expertsSubhead}
          experts={experts}
        >
          <CircleNew className="top right experts" />
        </Experts>

        {!showPressBar && (
          <Press quotes={pressQuotes} background="rgba(233,238,241,0.56)" />
        )}

        {!!productRating.reviewScore && !!initialReviews.length && (
          <Reviews
            reviews={initialReviews}
            productUrl={productAttributes.url}
            productName={productAttributes.name}
            productId={productAttributes.product_id}
            seeAllReviewsCta={true}
            isProductPage={true}
            {...productRating}
          >
            <AggregatedStarRating
              score={productRating.reviewScore}
              totalReviews={productRating.reviewCount}
              productId={product.id}
            />
            {mostHelpful && mostCritical && (
              <MostHelpfulReviews
                mostHelpful={mostHelpful}
                mostCritical={mostCritical}
              />
            )}
          </Reviews>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  reviewCountsMap: productSelectors.reviewCountsMap(state),
  reviewRatingsMap: productSelectors.reviewRatingsMap(state),
});

export default connect(mapStateToProps, {
  updateFooterUSPNotice,
})(ProductPageTemplate);

export const query = graphql`
  query ProductQuery($locale: String!, $slug: String!, $productId: String!) {
    mostHelpful: highlightReview(
      reviewType: { eq: "Most Helpful" }
      destination: { eq: "pdp" }
      product_slug: { eq: $slug }
    ) {
      id
      score
      content
      title
      user {
        display_name
      }
    }
    mostCritical: highlightReview(
      reviewType: { eq: "Most Critical" }
      destination: { eq: "pdp" }
      product_slug: { eq: $slug }
    ) {
      id
      score
      content
      title
      user {
        display_name
      }
    }
    allProductReview(
      sort: { fields: [created_at], order: DESC }
      filter: { product_id: { eq: $productId } }
    ) {
      edges {
        node {
          id
          product_id
          score
          votes_up
          votes_down
          content
          title
          sentiment
          created_at
          verified_buyer
          sticker
          age_range
          subscription_age
          would_recommend
          comment {
            id
            content
            created_at
          }
          user {
            user_id
            display_name
            social_image
          }
        }
      }
    }
    allContentfulProduct(
      filter: { node_locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          name {
            name
            childMarkdownRemark {
              html
            }
          }
          stockStatus
          pillSectionTitle {
            childMarkdownRemark {
              html
            }
          }
          pillSectionImage {
            description
            fluid(maxWidth: 470, maxHeight: 470, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          pillSectionAccordion {
            title
            content
          }
          summary
          headerDescription
          slug
          articles {
            __typename
            slug
            title
            previewText
            heroBackgroundColor
            heroSecondaryColor
            heroImage {
              file {
                url
              }
              desktop: fixed(
                width: 470
                height: 341
                quality: 90
                cropFocus: LEFT
              ) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
              mobile: fixed(
                width: 470
                height: 341
                quality: 90
                cropFocus: LEFT
              ) {
                ...GatsbyContentfulFixed_withWebp_noBase64
              }
            }
            ...expandPrimaryContent
          }
          ingredients {
            __typename
            name
            slug
            benefit
            nutritionFact
            productDosages {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            manufacturingLocation
            cardImage {
              title
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            image {
              fluid(maxWidth: 360, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            modalDescription
            modalDesktopImage {
              fluid(maxWidth: 770, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            modalMobileImage {
              fluid(maxWidth: 520, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            icons {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
          }
          valuePropIcons
          valuePropLabels
          timelineHeading {
            childMarkdownRemark {
              html
            }
          }
          timelineSubhead
          timelinePhoto {
            title
            desktop: fluid(maxWidth: 470, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            mobile: fluid(maxWidth: 280, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          timelineData {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          faq {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          ingredientsSubheader {
            childMarkdownRemark {
              html
              rawMarkdownBody
            }
          }
          somethingMissingTitle
          experts {
            name
            title
            image {
              fluid(maxWidth: 80, quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          doctorGuide {
            file {
              url
            }
          }
          price
          heroBackgroundColor
          heroFeaturedImages {
            title
            fluid(maxWidth: 555, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          heroZoomImages: heroFeaturedImages {
            title
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            description
          }
          pressQuotes {
            author
            icon
            link
            quote {
              quote
            }
          }
          pageTitle
          pageDescription
          socialImage {
            file {
              details {
                image {
                  width
                  height
                }
              }
              url
            }
          }
          price
          sku
          supplementFacts {
            servingSize
            servingsPerContainer
            labels {
              dv
            }
            nutrients {
              label
              dosage
              dv
            }
            ingredients {
              label
              dosage
              dv
            }
            notations {
              label
            }
            otherIngredients
            notationReferences
            attributions {
              label
            }
            simpleIngredients {
              name
              dosage
            }
          }
        }
      }
    }
    allContentfulClinical(
      filter: { node_locale: { eq: $locale }, product: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          title {
            childMarkdownRemark {
              html
            }
          }
          firstResult {
            childMarkdownRemark {
              html
            }
          }
          secondResult {
            childMarkdownRemark {
              html
            }
          }
          summaryTitle {
            childMarkdownRemark {
              html
            }
          }
          summary {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
