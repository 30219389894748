import React from "react";
import styled from "styled-components";
import { PressLogos } from "../../utils/svg";
import Container from "../Container";
import Row from "../Row";
import { Font, rem, responsive } from "../../utils/style";
import MagicLink from "../MagicLink";

const PressWrapper = styled.div.attrs({ id: "press-bar" })`
  background-color: ${p => (p.background ? p.background : "transparent")};
`;

const PressContainer = styled(Container).attrs({
  id: "press-bar_container",
})`
  height: 100%;
`;

const PressRow = styled(Row).attrs({
  id: "press-bar_container_row",
})`
  height: 100%;
`;

const PressColumn = styled.div.attrs({
  id: "press-bar_container_row_column",
  className: "col-12",
})`
  display: flex;
  padding-top: 48px;
  padding-bottom: 50px;
  justify-content: space-evenly;
`;

const PressQuoteWrapper = styled.div.attrs(p => ({
  id: `press-bar_container_row_column-wrapper_quote-${p.index}`,
  className: "col-4",
}))`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const PressIcon = styled.div.attrs(p => ({
  id: `press-bar_container_row_column-wrapper_quote-${p.index}_icon`,
}))`
  margin-bottom: 14px;
  svg {
    height: 40px;
    max-width: 100%;
  }
`;

const PressQuote = styled.p.attrs(p => ({
  id: `press-bar_container_row_column-wrapper_quote-${p.index}_quote`,
}))`
  ${Font.circular}
  font-size: ${rem(14)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
  margin-bottom: 0;
  width: 214px;
  ${responsive.md`
    font-size: ${rem(16)};
    line-height: 26px;
    width: 270px;
  `}
`;

const SVGWrapper = styled.div``;

const QuoteWrapper = styled.div`
  padding-top: 10px;
`;

export default class PressBar extends React.Component {
  render() {
    const { quotes, background } = this.props;

    return (
      <PressWrapper background={background}>
        <PressContainer>
          <PressRow>
            <PressColumn>
              {quotes.map((quote, i) => {
                const PressSvg = PressLogos[quote.icon];
                if (quote.link) {
                  return (
                    <PressQuoteWrapper index={i} key={i}>
                      <PressIcon index={i}>
                        <MagicLink
                          className={`press__icon__${quote.icon}`}
                          href={quote.link}
                          ariaLabel={quote.author}
                          rel="noopener noreferrer"
                        >
                          <SVGWrapper>
                            <PressSvg style={{ display: "inline-block" }} />
                          </SVGWrapper>
                          <QuoteWrapper>
                            {quote.quote && (
                              <PressQuote>“{quote.quote.quote}”</PressQuote>
                            )}
                          </QuoteWrapper>
                        </MagicLink>
                      </PressIcon>
                    </PressQuoteWrapper>
                  );
                }
                return (
                  <PressQuoteWrapper index={i} key={i}>
                    <PressIcon index={i}>
                      <PressSvg />
                    </PressIcon>
                    {quote.quote && (
                      <PressQuote>“{quote.quote.quote}”</PressQuote>
                    )}
                  </PressQuoteWrapper>
                );
              })}
            </PressColumn>
          </PressRow>
        </PressContainer>
      </PressWrapper>
    );
  }
}
