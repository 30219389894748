import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";

// Utils
import { Font, Color, rem, rgba, media, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";

// Styled Elements
const Ingredient = styled.div.attrs({
  className: "col-12 col-sm-6 col-md-4",
})``;

const IngredientContent = styled.button`
  appearance: none;
  border: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: rgba(242, 241, 245, 0.56);
  height: 156px;
  position: relative;
  margin-bottom: 24px;
  padding: 24px;
  z-index: 1;
  overflow: hidden;

  ${media.mobile`
    margin-bottom: 16px;
  `};

  ${responsive.sm`
    height: 180px;
  `};

  svg {
    circle {
      transition: all 0.2s;
    }
  }

  &:hover {
    svg {
      circle {
        fill-opacity: 0.56;
      }
    }
  }
`;

const Name = styled.h3`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(26)};
  line-height: ${rem(36)};
  font-weight: 500;
  text-align: left;
  letter-spacing: -0.3px;
  margin: 0 0 4px;
  z-index: 1;

  ${media.tablet`
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    margin: 0;
  `}
`;

const Benefit = styled.p`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
  text-align: left;
  letter-spacing: 0;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `};
`;

const Source = styled.span`
  ${Font.circular}
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  text-align: left;
  letter-spacing: 0;
  margin: 0;
  z-index: 1;
`;

const PlusIconWrapper = styled.span`
  position: absolute;
  bottom: 20px;
  right: 24px;
  margin: 0;
  z-index: 1;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  background-color: ${rgba(Color.ritualBlue, 0.24)};
  border-radius: 50%;
`;

export default class IngredientCard extends React.Component {
  render() {
    let { ingredient, handleClick } = this.props;
    let { name, manufacturingLocation, benefit, cardImage } = ingredient;

    const PlusIconElement = Icons["Plus"];
    return (
      <Ingredient>
        <IngredientContent onClick={handleClick}>
          <Img
            fluid={cardImage.fluid}
            alt={cardImage.title}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
              zIndex: -1,
            }}
          />
          <div className="d-flex flex-column align-items-start">
            <Name>{name}</Name>
            <Benefit>{benefit}</Benefit>
          </div>
          <Source>{manufacturingLocation}</Source>
          <PlusIconWrapper>
            <PlusIconElement />
          </PlusIconWrapper>
        </IngredientContent>
      </Ingredient>
    );
  }
}
